




.main-content {
	.content-highlight {
		padding: 10px;
		border: 1px solid #5f92c4;
		margin-top: 7px;
		margin-bottom: 7px;
	}
	.content-highlight-right {
		padding: 10px;
		border: 1px solid #5f92c4;
		margin: 80px 30px 20px;
		float: right;
		width: 220px;
		p {
			font-size: 12px;
		}
	}
	.image-left {
		max-width: 122px;
		float: left;
		margin: 0 20px 20px 0;
	}
	.color-swatch {
		display: flex;
		align-items: center;
		margin-left: 10px;
		img {
			width: 29px;
			height: 29px;
			margin-right: 16px;
		}
		p {
			max-width: 500px;
			font-size: 14px;
			color: #ECF9FF;
			line-height: 19px;
			margin: 0;
		}
	}
}





.home-intro-slideshow {
	background: #5f92c4;
	padding-bottom: 36px;
	img {
		max-width: 100%;
	}
	.slick-list {
		border-radius: 10px;
	}
	.slick-dots {
		text-align: left;
    padding-left: 6px;
    bottom: -30px;
		li {
			width: auto;
	    height: auto;
			&.slick-active {
				button {
					background: $color-white;
					color: $color-black;
				}
			}
			button {
				width: 30px;
		    height: 30px;
		    padding: 0;
				border: 1px solid $color-white;
				border-radius: 50%;
				color: $color-white;
		    font-size: 1rem;
		    line-height: 1;
				&:before {
			    display: none;
				}
			}
		}
	}
}


.home-intro {
	display: flex;
	flex-wrap: wrap;
	padding: 10px;
	.info-block {
		display: flex;
		flex: 1 1 calc(50% - 20px);
		margin: 10px;
		.info-block-inner {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
			width: 100%;
			border-radius: 10px;
			background-image: linear-gradient(180deg, $color-white 0%, rgba(163, 206, 236, 0.35) 100%);
			box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.22);
			&.home-video {
				background: $color-black;
			}
		}
	}
	.home-phones {
		width: 100%;
		background: url("../assets/images/home/home-phones.png") top 33% center no-repeat;
		background-size: 75% auto;
		padding: 60% 12% 6%;
		color: $color-white;
		text-decoration: none;
		p {
			margin: 0;
			text-align: center;
			letter-spacing: .05rem;
			font-weight: bold;
			text-shadow: 0px 1px 2px rgba(0,0,0,0.25);
			@include media-breakpoint-up(lg) {
				font-size: 1.2rem;
			}
			@include media-breakpoint-up(xl) {
				font-size: 1.5rem;
			}
		}
	}
	.home-intentions {
		flex: 1 1 100%;
		background: rgba(255, 255, 255, 0.35);
		border-radius: 10px;
		margin-bottom: 20px;
		padding: 17px 20px 2px;
		margin: 10px;
		h3 {
			color: #c2e1f5;
	    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
	    font-size: 14px;
	    margin-bottom: 3px;
		}
		p {
	    font-size: 16px;
			text-shadow: 0px 0px 6px rgba(255,255,255, 0.75);
		}
	}
}



.youtube-iframe {
	width: 100%;
	height: 100%;
	iframe {
		height: 100%;
		width: 100%;
		border: 0;
	}
}


.ubr-btn {
	background: $interface-dark-border;
	display: inline-block;
	padding: 6px 16px;
	color: white;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.2;
	border-radius: 8px;
	position: relative;
	&:before {
		content: '';
		box-shadow: inset 0px 8px 6px -6px rgba(255,255,255,0.5);
		position: absolute;
		top: 3px;
		left: 3px;
		width: calc(100% - 6px);
		height: calc(100% - 6px);
		border-radius: 5px;
	}
	&:hover {
		color: white;
		text-decoration: none;
	}
}



.styled-list {
	margin-left: 45px;
	&ul {
		list-style-type: disc;
		list-style-position: outside;
	}
	&ol {
		list-style-type: decimal;
		list-style-position: outside;
	}
	&.list-columns {
		margin-left: 0;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
		li {
			width: 190px;
			margin-left: 25px;
			float: left;
		}
	}
	li {
		font-size: 16px;
		line-height: 19px;
		padding-bottom: 5px;
	}
	a {
		font-size: 16px;
		color: $link-yellow;
		text-decoration: none;
		&:hover {
			color: $link-yellow-hover;
		}
	}
}


.room-description {
	border: 2px solid #FFFFFF;
	padding: 15px;
	border-radius: 8px;
	margin: 20px 0;
	@include media-breakpoint-up(lg) {
		margin: 20px 40px;
	}
}



.donation-box {
	background: $interface-dark-border;
	width: 314px;
	border-radius: 10px;
	padding: 15px;
	margin-bottom: 30px;
	box-shadow: inset -1px -1px 10px -3px rgba(0,0,0,0.5);
	.donation-box-header {
		font-family: verdana;
		font-size: 12px;
		color: #FFFFFF;
		font-weight: bold;
		margin-bottom:15px;
	}
	.donation-box-row {
		padding-bottom: 8px;
		float:left;
	}
	label {
		width: 109px;
		color: #FFFFFF;
		font-size: 12px;
		float: left;
		margin-top: 3px;
		font-family: sans-serif;
	}
	input[type='text'] {
		width: 165px;
		padding: 3px;
		height: 16px;
		border: 1px solid #C0C0C0;
		font-family: verdana;
		font-size: 12px;
		color: #092F68;
		margin-top: 2px;
		float: left;
	}
	textarea {
		padding: 3px;
		border: 1px solid #C0C0C0;
		font-family: verdana;
		font-size: 12px;
		color: #092F68;
		width: 100%;
		height: 100px;
	}
	input[type='submit'] {
		margin-top: 10px;
		border-radius: 5px;
		color: $interface-border;
		padding: 2px 16px;
		font-weight: bold;
	}
}





.testimonials {
	border-collapse: separate;
	border-spacing: 0 20px;
	td {
		text-align: center;
		vertical-align: top;
		padding: 20px;
		border-radius: 8px;
		box-shadow: 0px 0px 17px #0a568a;
		border: 2px solid #60A0E8;
		> blockquote {
			text-align: center;
			* {
				text-align: center;
			}
		}
		> p {
			margin: 0;
			font-size: 15px;
			font-weight: 400;
			text-align: center;
		}
	}
}

// .test-btn {
// 	position: relative;
// 	background: red;
// 	color: white;
// 	padding: 20px;
//   border-radius: 10px;
//   box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4), inset 0px 50px 50px -25px rgba(255,255,255,0.9);
// 	&:after {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     box-shadow: inset 3px -3px 8px 1px rgba(255,255,255,0.75);
//     border-radius: 10px;
// 	}
// }



.get-involved {
	background: url('../assets/images/old/GetInvolvedPageBackground.jpg') no-repeat;
	background-size: 100% auto;
	position: relative;
	border-radius: 1.5%;
	&:before {
		content: '';
		display: block;
		padding-top: 97%;
	}
	.get-involved-buttons {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: 36% 0 0;
		> a {
			display: block;
			margin: 0 auto 12px;
			width: 90%;
			max-width: 500px;
			background: linear-gradient(0deg, #eee, #fff);
			padding: 6px 26px;
			font-size: 2rem;
			font-family: serif;
			border-radius: 5px;
			color: $interface-dark-border;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.form-signup {
	label {
		display: block;
	}
	input[type="text"] {
		display: block;
		width: 100%;
		max-width: 500px;
	}
	input[type="submit"] {
		display: block;
	}
}
