
	$page-bg: #097dc9;
	$interface-bg: #2172A9;
	$interface-bg-hover: #2F94D7;
	// $interface-bg-hover2: #2686C6;
	$interface-bg-shadow: #1282ca;
	$interface-border: #86C0E8;
	$interface-dark-bg: #1b79bb;
	$interface-dark-border: #0a4a78;
	$interface-light-bg-shadow: #86c4ed;

	$color-white: #FFF;
	$color-offwhite: #ECFBFF;
	$color-light-blue: #c2e1f5;
	$color-black: #000;

	$link-yellow: #FFFF99;
	$link-yellow-hover: #FFFFCC;

	$color-red: red;

	$section-border: #5f92c4;



$header-height-mobile: 110px;
$header-height-desktop: 160px;
