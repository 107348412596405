
.OrbWrapper {
	z-index: 0;
	position: relative;
	width: 100%;
	height: calc(100vh - #{$header-height-mobile});
	min-height: 400px;
	background-color: #26b4d8;//$interface-light-bg-shadow;
	background: url("../assets/images/listening.png") no-repeat top center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	@include media-breakpoint-up(lg) {
		height: calc(100vh - #{$header-height-desktop});
	}
	&.fullscreen {
		z-index: 100;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		.OrbContainer {
			height: 100%;
		}
	}
}

.OrbInstructContainer {
	z-index: 0;
	visibility: hidden;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	@include flexbox();
	@include flex-direction(column);
	@include align-items(center);
	@include justify-content(center);
	width: 100%;
	height: 100%;
	padding: 20px;
	color: #106478;
	text-align: center;
	font-weight: bold;
	transition: opacity 4s, visibility 0s step-end 4s;
	&:before {
		content: '';
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url("../assets/images/room/room-intro.png") no-repeat top center;
    background-size: auto calc(100% - 80px);
		opacity: 0.9;
	}
	&.show {
		visibility: visible;
		opacity: 1;
	}
	h1 {
		font-size: 2.3rem;
		font-weight: bold;
		@include media-breakpoint-down(sm) {
			font-size: 1.6rem;
		}
	}
	p {
		max-width: 700px;
    font-size: 1.5rem;
    line-height: 1.5;
		font-weight: bold;
		@include media-breakpoint-down(sm) {
			font-size: 1.5rem;
	    line-height: 1.5;
			font-weight: normal;
		}
	}
	.btn,
	.btn-light {
		opacity: 0.65;
		padding: 8px 34px;
    background: $color-white;
		border: 0 none;
		border-radius: 12px;
		box-shadow: 0 0 0 0 rgba(255,255,255, 0.35);
		color: #41a2bb;
    font-size: 1.2rem;
		text-shadow: 0px 0px 1px rgba(0,0,0,0.1);
		transition: opacity 1s;
		&:hover,
		&:focus,
		&:not(:disabled):not(.disabled):active,
		&.btn-light:not(:disabled):not(.disabled).active {
			color: #41a2bb;
	    background-color: white;
	    border-color: white;
			opacity: 0.85;
			box-shadow: 0 0 12px 3px rgba(255,255,255, 0.35);
		}
	}
}


/* Begin UBR ORB Block ------------------------------------------------*/

.OrbContainer {
	z-index: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	padding: 0;
	margin: 0;
	&.show {
		opacity: 1;
		visibility: visible;
		.OrbBarTimerContainer {}
		.OrbBreathStatus {
			opacity: 1;
		}
		.OrbImage {
			opacity: 1;
		}
		.OrbIntentContainer {}
		.OrbSyncContainer {}
		.OrbCountContainer {
			// opacity: 1;
		}
		.OrbBarContainer {}
	}
	.OrbContainerTop,
	.OrbContainerMiddle,
	.OrbContainerBottom {
		position: absolute;
		left: 0;
		width: 100%;
	}
	.OrbContainerTop {
		top: 0;
		height: 105px;
	}
	.OrbContainerMiddle {
		top: 105px;
		height: calc(100% - 275px);
	}
	.OrbContainerBottom {
		bottom: 0;
		height: 170px;
	}
}

.OrbBreathStatus {
	opacity: 0;
	position: absolute;
	top: 60px;
	left: 30%;
	width: 40%;
	transition: opacity 2s 6s;
	p {
		margin: 0;
		font-size: 1.5rem;
		font-weight: bold;
		text-align: center;
		text-shadow: 0px 1px 2px rgba(0,0,0,0.1);
	}
}



@-webkit-keyframes intention-loop-anim {
  0% {
		opacity: 0;
		@include transform(scale(0.5));
	}
  20% {
		opacity: 0.9;
		@include transform(scale(1));
	}
  40% {
		opacity: 0.9;
		@include transform(scale(1));
	}
	80% {
		opacity: 0;
		@include transform(scale(0.5));
	}
	100% {
		opacity: 0;
		@include transform(scale(0.5));
	}
}
@keyframes intention-loop-anim {
	0% {
		opacity: 0;
		@include transform(scale(0.5));
	}
  20% {
		opacity: 0.9;
		@include transform(scale(1));
	}
  40% {
		opacity: 0.9;
		@include transform(scale(1));
	}
	80% {
		opacity: 0;
		@include transform(scale(0.5));
	}
	100% {
		opacity: 0;
		@include transform(scale(0.5));
	}
}


.OrbIntentContainer {
	visibility: hidden;
	opacity: 0;
	@include flexbox();
	@include align-items(center);
	@include justify-content(center);
	height: 83%;
	position: relative;
	&.show {
		opacity: 1;
		visibility: visible;
		p {
			-webkit-animation: intention-loop-anim 12s;
		  -webkit-animation-iteration-count: infinite;
			-webkit-animation-delay: 6s;
		  animation: intention-loop-anim 12s;
		  animation-iteration-count: infinite;
			animation-delay: 6s;
		}
	}
	p {
		opacity: 0;
		@include transform(scale(0.5));
		display: inline-block;
		margin: 0;
		font-size: 2.5rem;
		font-weight: bold;
		line-height: 1.5;
		text-align: center;
		text-shadow: 0px 1px 5px rgba(0,0,0,0.15);
		transition: -webkit-transform 6s, -ms-transform 6s, transform 6s;
		@include media-breakpoint-down(xs) {
			font-size: 2rem;
		}
	}
}


.OrbImage {
	opacity: 0;
	z-index: 0;
	position: absolute;
	left: 50%;
	top: 40%;
	height: 185%;
	@include transform(translate(-50%, -50%));
	transition: all 6s;
	// @include media-breakpoint-up(lg) {
	// 	width: 130%;
	// }
	&.Exhale {
		height: 135%;
		// @include media-breakpoint-up(lg) {
		// 	width: 80%;
		// }
	}
}

.OrbBack {
	z-index: 0;
	position: relative;
	height: 100%;
	width: auto;
}
.OrbColor,
.OrbTop {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	width: auto;
	width: auto;
	height: 98%;
}
.OrbColor {
	z-index: 1;
}
.OrbTop {
	z-index: 2;
	opacity: 0.6;
}


/* END UBR ORB Block ------------------------------------------------*/

.OrbCountContainer {
	position: absolute;
	left: 50%;
  bottom: 70px;
	@include transform(translate(-50%, 0));
	@include media-breakpoint-down(md) {
		bottom: 90px;
	}
	@include flexbox();
	@include align-items(center);
	visibility: hidden;
	overflow: hidden;
	min-width: 250px;
	max-height: 0;
	opacity: 0;
	margin: 5px;
	padding: 5px 15px;
	background: rgba(255,255,255,0.25);
	border: 1px solid $color-white;
	border-radius: 5px;
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	text-align: center;
	vertical-align: middle;
	transition: opacity 2s;
	-webkit-animation: fade-out-anim 2s;
	-webkit-animation-iteration-count: 1;
	animation: fade-out-anim 2s;
	animation-iteration-count: 1;
	&.show {
		opacity: 0.9;
		visibility: visible;
		max-height: 1000px;
		-webkit-animation: fade-in-anim 2s;
		-webkit-animation-iteration-count: 1;
		animation: fade-in-anim 2s;
		animation-iteration-count: 1;
	}
	p {
		margin: 0;
		padding-left: 10px;
		color: $color-white;
		text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
	}
	.OrbCountCircle {
		z-index: 0;
	  position: relative;
	  color: #106478;
	  font-size: 20px;
	  text-align: center;
	  line-height: 50px;
	  width: 50px;
		min-width: 50px;
	  height: 50px;
		&:after {
			content: '';
			z-index: -1;
	    position: absolute;
			top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
			background: $color-white;
			border-radius: 50%;
		}
	}
}

.OrbBarTimerContainer {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	left: 0;
	top: 0;
	margin: 10px;
	padding: 5px 15px 5px 40px;
	background: $color-white;
	border-radius: 5px;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	color: #106478;
	transition: opacity 2s;
	text-transform: uppercase;
	letter-spacing: .05rem;
	&:before {
		content: '';
		background: url("../assets/images/room/icon-time.png") no-repeat center center;
		background-size: auto 72%;
		position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
	}
	&.show {
		opacity: 0.9;
		visibility: visible;
	}
}

.OrbBarContainer {
	position: absolute;
	left: 0;
	bottom: 0;
	opacity: 0;
	visibility: hidden;
	@include flexbox();
	@include justify-content(space-between);
	@include align-items(center);
  width: 100%;
	padding: 4px 12px;
	background: $color-white;
	color: $color-black;
	box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.1);
	transition: opacity 4s;
	&.show {
		opacity: 0.9;
		visibility: visible;
	}
	> div {
		color: #106478;
		&.room-name {
			width: 25%;
			@include media-breakpoint-down(sm) {
				display: none;
			}
			p {
				margin: 0;
				font-weight: bold;
				text-align: left;
			}
		}
		&.room-info {
			width: 50%;
			text-align: center;
			@include media-breakpoint-down(sm) {
				width: auto;
				text-align: left;
			}
		}
		&.room-fullscreen {
			width: 25%;
			text-align: right;
			@include media-breakpoint-down(sm) {
				width: auto;
			}
		}
	}
	button,
	.room-time,
	.room-people {
		display: inline-block;
    white-space: nowrap;
		margin: 5px 10px;
    padding: 0 15px;
		border: 0 none;
    border-radius: 8px;
		background: rgba(16, 100, 120, 0.1);
		box-shadow: inset 0px 0px 4px -2px rgba(16, 100, 120, 0.8), 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
		color: #106478;
		text-transform: uppercase;
		@include media-breakpoint-down(sm) {
			margin: 5px;
			font-size: 0.85rem;
		}
	}
	button {}
	.room-time {}
	.room-people {
		position: relative;
		padding: 0 15px 0 50px;
		&:before {
			content: '';
			background: url("../assets/images/room/icon-people.png") no-repeat center center;
			background-size: auto 72%;
			position: absolute;
	    top: 0;
	    left: 0;
	    width: 50px;
	    height: 100%;
		}
	}
}


/* Begin UBR Sync Text Block ------------------------------------------------*/

@-webkit-keyframes fade-in-anim {
  0% { max-height: 0; visibility: hidden; opacity: 0; }
	1% { max-height: 1000px; visibility: visible; }
  100% { opacity: 0.9; }
}
@keyframes fade-in-anim {
  0% { max-height: 0; visibility: hidden; opacity: 0; }
	1% { max-height: 1000px; visibility: visible; }
  100% { opacity: 0.9; }
}

@-webkit-keyframes fade-out-anim {
  0% { opacity: 0.9; visibility: visible; max-height: 1000px; }
  99% { opacity: 0; }
  100% { max-height: 0; visibility: hidden; }
}
@keyframes fade-out-anim {
  0% { opacity: 0.9; visibility: visible; max-height: 1000px; }
  99% { opacity: 0; }
  100% { max-height: 0; visibility: hidden; }
}

.OrbSyncContainer {
	position: absolute;
  left: 50%;
  top: 40%;
	@include flexbox();
	@include flex-direction(column);
	@include align-items(center);
	visibility: hidden;
	overflow: hidden;
	max-height: 0;
	opacity: 0;
	text-align: center;
  @include transform(translate(-50%, -50%));
	transition: opacity 2s;
	-webkit-animation: fade-out-anim 2s;
	-webkit-animation-iteration-count: 1;
	animation: fade-out-anim 2s;
	animation-iteration-count: 1;
	&.show {
		opacity: 0.9;
		visibility: visible;
		max-height: 1000px;
		-webkit-animation: fade-in-anim 2s;
		-webkit-animation-iteration-count: 1;
		animation: fade-in-anim 2s;
		animation-iteration-count: 1;
	}
	p {
		margin: 0;
		padding: 0;
    font-size: 1.6rem;
		font-weight: bold;
    text-align: center;
    color: $color-white;
		@include media-breakpoint-down(sm) {
			font-size: 1.4rem;
			line-height: 1.2;
		}
	}
	.OrbCountCircle {
		z-index: 0;
	  position: relative;
		margin-bottom: 0.8rem;
		&:after {
			content: '';
			z-index: -1;
	    position: absolute;
			top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
			background: $color-white;
			border-radius: 50%;
		}
		> div {
			color: $color-black;
			font-size: 3rem;
	    text-align: center;
	    width: 4.8rem;
	    height: 4.8rem;
	    line-height: 1.6;
			opacity: 0.5;
			@include media-breakpoint-down(sm) {
				font-size: 2rem;
		    width: 3.2rem;
		    height: 3.2rem;
			}
		}
	}
}
