
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: 320px;
	height: 100%;
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}


h1 {
	font-size: 1.25rem;
}
h2 {
	font-size: 1.1875rem;
	color: $color-offwhite;
}
h3 {
	font-size: 1.125rem;
}
p {
	font-size: 1rem;
	text-align: justify;
	a {
		color: $link-yellow;
		text-decoration: none;
		&:hover {
			color: $link-yellow-hover;
			text-decoration: underline;
		}
	}
}


.btn-primary {
	padding: 0.35rem 0.95rem;
	border: 3px solid transparent;
	border-radius: 8px;
	font-size: 15px;
	font-weight: bold;
	line-height: 1;
	letter-spacing: 0.025rem;
	text-align: center;
	text-decoration: none;
	box-shadow: inset 0 12px 6px -8px rgba(255, 255, 255, 0.35);
	background: $interface-bg;
	text-shadow: 0 0 2px rgba(0,0,0,0.3);
	&:hover,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		background: $page-bg;
    border-color: transparent;
		box-shadow: inset 0 12px 6px -8px rgba(255, 255, 255, 0.35);
	}
}




.page-background {
	z-index: -1;
	position: fixed;
	width: 100%;
	height: 100%;
	background: url("../assets/images/bg-top-clouds.png") no-repeat center top $page-bg;
	background-size: 100% auto;
	&:before {
		opacity: 0.5;
		content: '';
		position: absolute;
		width: 100vw;
		height: 100vh;
		background: url("../assets/images/bg-bottom-clouds.png") no-repeat center bottom;
		background-size: 100% auto;

	}
}







.header {
	z-index: 2;
	@include flexbox();
	@include align-items(stretch);
	position: fixed;
	width: 100%;
	height: $header-height-mobile;
	background: url("../assets/images/bg-top-clouds.png") no-repeat center top $page-bg;
	background-size: 100% auto;
	@include media-breakpoint-up(lg) {
		height: $header-height-desktop;
		padding-bottom: 1rem;
	}
	> .navbar {
		@include flex-direction(column);
		width: 95vw;
		min-width: 300px;
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
		padding: 0;
	}
	.header-top {
		@include flexbox();
		@include justify-content(space-between);
		@include align-items(center);
		@include flex-grow(1);
		width: 100%;
		padding-left: 5%;
		.logo {
			width: 50%;
			min-width: 220px;
			max-width: 280px;
			text-decoration: none;
			img {
				max-width: 100%;
				max-height: 80px;
			}
		}
		.eyebrow {
			@include flexbox();
			@include align-items(center);
			.paypal-donate {
				img {
					position: absolute;
				}
			}
			> a,
			.paypal-donate input[type="button"] {
				display: block;
				position: relative;
				margin-left: 5px;
				padding: 8px 20px;
				background-color: $interface-bg;
				border: 1px solid $interface-border;
				border-radius: 8px;
				box-shadow: 0 0 5px 0px rgba(134, 192, 232, 0.8);
				color: $color-white;
				font-size: 14px;
				font-weight: bold;
				line-height: 1.2;
				text-decoration: none;
				text-shadow: 0px 1px 2px rgba(0,0,0,0.25);
				@include media-breakpoint-down(xs) {
					display: none;
				}
				&:before {
					content: '';
					position: absolute;
					top: 3px;
					left: 3px;
					width: calc(100% - 6px);
					height: calc(100% - 6px);
					border-radius: 5px;
					box-shadow: inset 0px 8px 6px -6px rgba(255,255,255,0.5);
				}
				&:hover {
					background-color: $interface-bg-hover;
					color: $color-white;
					text-decoration: none;
				}
			}
			.navbar-toggler {
				position: relative;
				width: 50px;
				height: 50px;
				margin-left: 15px;
				border: 0 none;
				opacity: 0.9;
				&.collapsed {
					span {
						&:nth-child(1) {
							top: 10px;
							@include transform(rotate(0deg));
						}
						&:nth-child(2) {
							@include transform(rotate(0deg));
						}
						&:last-child {
							opacity: 1;
						}
					}
				}
				span {
					display: block;
					position: absolute;
					right: 5px;
					width: 40px;
					height: 4px;
					background-color: $color-white;
					border-radius: 4px;
					box-shadow: 0 0 2px 1px rgba(255,255,255,0.2);
					// transition: all 0.1s ease-in-out;
					&:nth-child(1) {
						top: 22px;
						@include transform(rotate(45deg));
					}
					&:nth-child(2) {
						top: 22px;
						@include transform(rotate(-45deg));
					}
					&:last-child {
						margin-bottom: 0;
						top: 34px;
						opacity: 0;
					}
				}
			}
		}
	}
	.main-nav {
		width: 100%;
		box-shadow: 0px 10px 12px -10px $interface-bg-shadow;
		@include flex-grow(0);
		@include media-breakpoint-down(md) {
			z-index: 1;
			position: fixed;
			left: 0;
			top: 100px;
			height: calc(100vh - 100px);
		}
		&.show {
			@include media-breakpoint-down(md) {
				overflow: auto;
				background: rgba(0,0,0,0.3);
				border-radius: 5px;
			}
		}
		.navbar-nav {
			position: relative;
			width: 100%;
			@include media-breakpoint-down(md) {
				background: $interface-bg;
			}
			&:before {
				@include media-breakpoint-up(lg) {
					content: '';
					position: absolute;
					top: 1px;
					left: 2%;
					width: 96%;
					height: calc(100% - 2px);
					background: linear-gradient(0deg, $interface-bg 20%, transparent 100%);
				}
			}
		}
		.nav-item {
			position: relative;
			width: 100%;
			background: transparent;
			border-radius: 8px;
			text-decoration: none;
			@include media-breakpoint-up(lg) {
				margin: 0 1px;
				border-radius: 0;
			}
			@include media-breakpoint-down(md) {
				background: $color-light-blue;
			}
			&:first-child {
				@include media-breakpoint-up(lg) {
					border-radius: 8px 0 0 8px;
					&:before {
						border-radius: 8px 0 0 8px;
					}
					.nav-link {
						border-radius: 8px 0 0 8px;
						&:before {
							left: 1px;
							width: calc(100% - 4px);
							border-radius: 8px 0 0 8px;
						}
						&:after {
							border-radius: 8px 0 0 8px;
						}
					}
				}
			}
			&:last-child {
				@include media-breakpoint-up(lg) {
					border-radius: 0 8px 8px 0;
					.nav-link {
						border-radius: 0 8px 8px 0;
						&:before {
							border-radius: 0 8px 8px 0;
						}
						&:after {
							width: calc(100% - 4px);
							border-radius: 0 8px 8px 0;
						}
					}
				}
			}
			.nav-link {
				z-index: 1;
				position: relative;
				padding: 0.65rem 0.35rem;
				background: rgba(0, 90, 156, 1);
				border: 0;
				color: $color-white;
				font-family: Arial, Helvetica, sans-serif;
				font-size: 15px;
				font-weight: bold;
				line-height: 1;
				text-shadow: 0px 1px 2px rgba(0,0,0,0.25);
				@include media-breakpoint-up(lg) {
					// background-image: linear-gradient(0deg, transparent 0%, rgba(255, 255, 255, 0.2) 50%, transparent 50%, rgba(255, 255, 255, 0.2) 100%);
					background-image: linear-gradient(0deg, transparent 50%, rgba(255, 255, 255, 0.125) 100%);
				}
				&:before {
					content: '';
					z-index: -1;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: radial-gradient(farthest-corner at 50% 95%, rgba(123, 207, 242, 0.75) 20%, rgba(0, 0, 0, 0) 100%);
					box-shadow: inset 0px -4px 6px -6px rgba(0,0,0,0.8);
					border-radius: 3px;
				}
				&:after {
					content: '';
					z-index: -1;
					position: absolute;
					top: 1px;
					left: 3px;
					width: calc(100% - 6px);
					height: calc(100% - 2px);
					margin: 0;
					border: 0 none;
					box-shadow: inset 0px 6px 8px -6px rgba(255,255,255,0.9);
					border-radius: 1px;
				}
				&:hover {
					&:after {
						background-image: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(0,0,0,0) 100%);
					}
				}
			}
		}
		.dropdown-menu {
			min-width: 100%;
			margin-top: 0;
			padding: 8px 8px 0;
			border-radius: 0 0 5px 5px;
			box-shadow: inset 0px -6px 10px -3px $interface-light-bg-shadow, inset 0px 8px 6px -6px $interface-light-bg-shadow, 0px 1px 4px -2px rgba(0,0,0,0.3);
			@include media-breakpoint-down(md) {
				position: relative;
			}
			> a {
				display: block;
				padding: 5px 8px;
				margin: 0 0 8px;
				background: transparent;
				color: $color-black;
				&:last-child {
					padding-bottom: 5px;
				}
				&:hover {
					opacity: 0.75;
				}
			}
		}
	}
}






.site-wrapper {
	position: relative;
	width: 95vw;
	min-width: 300px;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
	padding-top: $header-height-mobile;
	@include media-breakpoint-up(lg) {
		padding-top: $header-height-desktop;
	}
}




.main-content {
	.sidebar-content {
		@include media-breakpoint-up(lg) {
			@include flexbox();
			@include align-items(flex-start);
			@include justify-content(space-between);
		}
		.side-nav,
		.content-area {}
		.side-nav {
			@include media-breakpoint-up(lg) {
				position: relative;
				@include order(0);
				@include flex(0, 0, 325px);
				@include align-self(stretch);
				padding-right: 25px;
				&:before {
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					background: url("../assets/images/side-nav-divider.png") no-repeat top 5% right;
					background-size: auto 90%;
					opacity: 0.45;
				}
			}
			> .card {
				padding: 2px;
				background: transparent;
				background-color: rgba(255,255,255,0.25);
				border: 0 none;
				border-radius: 6px;
				box-shadow: inset 3px -3px 3px 1px rgba(255,255,255,0.2), 0px 0px 2px 0px rgba(0,0,0,0.2);
				.card-header {
					position: relative;
					margin: -2px -2px 0;
					padding: .25rem 1.25rem;
					background: #2172A9;
					border-radius: 6px 6px 1px 1px;
					box-shadow: inset 0px 32px 5px -15px rgba(255, 255, 255, 0.25), inset 0px -2px 4px -2px rgba(0,0,0,0.25);
					text-shadow: 0px 1px 2px rgba(0,0,0,0.25);
					&:after {
						content: '';
						position: absolute;
						top: 3px;
						left: 3px;
						width: calc(100% - 6px);
						height: calc(100% - 6px);
						border-radius: 4px 4px 0 0;
						box-shadow: inset 0px 6px 5px -5px rgba(255, 255, 255, 0.9);
					}
				}
				.card-body {
					padding: 12px;
				}
			}
		}
		.content-area {
			overflow: hidden;
			margin-bottom: 15px;
			background: $interface-dark-bg;
			border-radius: 5px;
			box-shadow: inset 0px 0px 20px 3px $interface-dark-border;
			@include media-breakpoint-up(lg) {
				@include order(1);
				@include flex(1, 1, calc(100% - 345px));
			}
			.image-right {
				z-index: 1;
				float: right;
				position: relative;
				max-width: 40%;
				margin: 0 0 15px 25px;
				opacity: 0.75;
			}
			.top-heading {
				position: relative;
				padding: 14px 24px 22px;
				box-shadow: inset 0px -30px 15px -20px rgba(0,0,0,0.25);
				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 100%;
					width: 100%;
					height: 100%;
					box-shadow: inset 0px 15px 20px -15px rgba(255,255,255,0.35);
				}
			}
			.inner-content {
				padding: 10px 24px;
				.content-block {
					@include media-breakpoint-up(lg) {
						@include flexbox();
					}
					.content-block-left {
						@include media-breakpoint-up(lg) {
							@include flex(1, 1, auto);
							width: 100%;
							min-width: 235px;
						}
					}
					.content-block-right {
						padding: 20px;
						border: 1px solid #5f92c4;
						@include media-breakpoint-up(lg) {
							@include flex(0, 0, 35%);
							width: 35%;
							min-width: 300px;
							margin: 0 0 20px 30px;
						}
						p {
							font-size: 12px;
						}
						table {
							width: 100%;
							margin-bottom: 10px;
							tr {
								td {
									padding: 2px 5px;
									vertical-align: top;
								}
							}
						}
					}
				}
			}
		}
	}
	.full-width-content {
		@include flex(0, 0, 100%);
		.inner-content {
			padding: 10px 24px;
			background: $interface-dark-bg;
		}
	}
	.content-wrapper {
		overflow: hidden;
		margin-bottom: 10px;
		border-radius: 5px;
	}
}



.side-nav {
	> .card {
		&.rooms-card {
			.card-body {
				.room-box {
					display: inline-block;
					width: 100%;
					max-width: 300px;
					padding: 2px 12px 12px;
					background-color: rgba(255, 255, 255, 0.3);
					border-radius: 5px;
					box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25), inset 0 0 10px -1px rgba(251, 247, 193, 0.9);
					text-decoration: none;
					&:hover {
						.enter-button {
							background: $page-bg;
						}
					}
					.room-icon {
						width: 20%;
						margin: .5rem .85rem;
					}
					.room-name {
						margin: .5rem .85rem;
						color: $interface-bg-hover;
						text-shadow: 0px 0px 1px rgba(0,0,0,0.1);
						.live {
							color: $color-red;
						}
					}
					.enter-button {
						display: inline-block;
						position: relative;
						width: 35%;
						min-width: 80px;
						margin-right: 10px;
						padding: .5rem .85rem;
						background: $interface-bg;
						border-radius: 8px;
						color: $color-white;
						font-size: 14px;
						font-weight: bold;
						line-height: 1;
						text-align: center;
						text-transform: uppercase;
						text-shadow: 0px 1px 2px rgba(0,0,0,0.25);
						&:before {
							content: '';
							position: absolute;
							top: 3px;
							left: 3px;
							width: calc(100% - 6px);
							height: calc(100% - 6px);
							border-radius: 5px;
							box-shadow: inset 0px 8px 6px -6px rgba(255,255,255,0.5);
						}
						&:hover {
							color: white;
							text-decoration: none;
						}
					}
					.people-button {
						display: inline-block;
						z-index: 0;
						position: relative;
						width: 100%;
						padding: .5rem .85rem .5rem 30%;
						background: rgba(205, 240, 255, 0.9);
						border-radius: 8px;
						color: $interface-bg;
						font-size: 14px;
						font-weight: bold;
						line-height: 1;
						text-align: left;
						&:before {
							content: '';
							z-index: -1;
							position: absolute;
							top: 3px;
							left: 3px;
							width: calc(100% - 6px);
							height: calc(100% - 6px);
							border-radius: 5px;
							box-shadow: inset 0px 8px 8px -3px rgba(255, 255, 255, 0.9);
						}
						&:hover {
							color: $interface-bg;
							text-decoration: none;
						}
						&:after {
							content: '';
							position: absolute;
							left: 0;
							top: 0;
							width: 20%;
							height: 100%;
							background: url("../assets/images/icon-person-sm.png") no-repeat center right;
							background-size: 40% auto;
						}
					}
				}
			}
		}
		&.signup-card {
			.card-header {}
			.card-body {
				input[type="text"] {
					display: block;
					width: 100%;
					margin-bottom: 10px;
					padding: 4px 10px;
				}
				// .btn {
				// 	padding: 0.35rem 0.95rem;
			  //   border: 3px solid transparent;
			  //   border-radius: 8px;
			  //   font-size: 15px;
			  //   font-weight: bold;
			  //   line-height: 1;
			  //   letter-spacing: 0.025rem;
			  //   text-align: center;
			  //   text-decoration: none;
			  //   box-shadow: inset 0 12px 6px -8px rgba(255, 255, 255, 0.35);
			  //   background: $interface-bg;
			  //   text-shadow: 0 0 2px rgba(0,0,0,0.3);
				// 	&:hover {
				// 		color: $page-bg;
				// 		text-decoration: none;
				// 	}
				// }
			}
		}
	}
}




.footer {
	.navbar {
		position: relative;
		padding: 4px 16px;
		border-radius: 5px;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4), inset 0px 30px 30px -15px rgba(255, 255, 255, 0.4);
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 5px;
			box-shadow: inset 2px -2px 5px 1px rgba(255, 255, 255, 0.4);
		}
		.navbar-brand {
			padding: .25rem 0;
			font-size: 12px;
			text-shadow: 0px 1px 2px rgba(0,0,0,0.25);
			color: $color-light-blue;
		}
		.nav-link {
			padding: .25rem .5rem;
			font-size: 12px;
			text-shadow: 0px 1px 2px rgba(0,0,0,0.25);
			color: $color-light-blue;
		}
	}
}
